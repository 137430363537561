/* line 11, scss/70-modules/homepageimagecarousel/_module.scss */
.is-hidden-slide {
	visibility: hidden;
	top: -40px;
}

/* line 15, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--slide .is-hidden-slide {
	margin-left: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single--slide .is-hidden-slide {
	margin-left: initial;
	margin-right: 100%;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-single--slide .is-hidden-slide {
		margin-left: 0;
	}
}

/* line 19, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--none .is-hidden-slide {
	top: 0;
}

/* line 23, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--fade .is-hidden-slide, .is-single--detailsanimation .is-hidden-slide {
	z-index: 1;
	opacity: 0;
}

@keyframes carousel-type {
	from {
		width: 0;
	}
}

@keyframes carousel-show {
	0% {
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* line 43, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel--typing-caption .is-center .carousel__caption-title {
	width: 100%;
	animation: carousel-type 4s steps(60, end);
	white-space: nowrap;
	overflow: hidden;
	display: block;
	color: rgb(var(--color-global-positive));
}

/* line 52, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel--typing-caption .is-center .carousel__details-more-link {
	animation: carousel-show 1.3s steps(30, end);
}

/* line 57, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel--typing-caption .carousel__details {
	left: 20px;
	right: auto;
	bottom: 20px;
	max-width: calc(100% - 20px * 2);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-carousel--typing-caption .carousel__details {
	left: auto;
	right: 20px;
}

@media only screen and (max-width: 47.999em) {
	/* line 57, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel--typing-caption .carousel__details {
		left: 10px;
		right: 10px;
	}
}

/* line 69, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel.is-top {
	left: 0;
	z-index: 0;
	top: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-carousel.is-top {
	left: auto;
	right: 0;
}

/* line 75, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel.is-fullscreen {
	height: 100vh !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 0;
	margin-bottom: 0;
	overflow: visible;
}

/* line 87, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel.is-fixed .carousel__wrapper {
	z-index: 0;
	left: 0;
	position: fixed;
	top: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-carousel.is-fixed .carousel__wrapper {
	left: auto;
	right: 0;
}

/* line 95, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel.is-fixed:not(.m-carousel--fade) .carousel__container {
	-webkit-transition: all 0s !important;
	transition: all 0s !important;
}

/* line 103, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel.carousel__video-rotator {
	overflow: visible;
}

/* line 106, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel.carousel__video-rotator .carousel__container {
	position: relative;
}

/* line 111, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .o-imagenav {
	background-color: rgba(0, 0, 0, 0.5);
}

/* line 118, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .o-imagenav:hover, .m-carousel .o-imagenav:focus {
	background-color: rgba(var(--color-global-negative), 0.7);
}

/* line 124, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__title {
	text-transform: uppercase;
	margin: 20px 0;
	text-align: center;
}

/* line 130, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__subtitle {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	font-size: 1.167em;
	margin-bottom: 40px;
	text-align: center;
}

@media only screen and (max-width: 47.999em) {
	/* line 130, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__subtitle {
		font-size: 1em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 130, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__subtitle {
		font-size: 1em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 130, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__subtitle {
		font-size: 1em;
		line-height: 1.4;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 130, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__subtitle {
		font-size: 1.35956em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 130, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__subtitle {
		font-size: 1.167em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 130, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__subtitle {
		font-size: 1.167em;
	}
}

/* line 138, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__caption-title-wrapper {
	border-bottom: 1px solid rgb(var(--color-global-positive));
}

/* line 142, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__caption-title--custom {
	font-family: var(--font-family-headings);
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: block;
	color: rgb(var(--color-global-secondary));
}

@media only screen and (max-width: 47.999em) {
	/* line 142, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__caption-title--custom {
		font-size: 1.0519em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 142, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__caption-title--custom {
		font-size: 1.14838em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 142, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__caption-title--custom {
		font-size: 1.34em;
		line-height: 1.5;
	}
}

/* line 151, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__caption-title--vertical {
	margin-bottom: 20px;
	color: rgb(var(--color-global-positive));
}

/* line 156, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__caption-title--typing {
	font-size: 1.833em;
	text-transform: uppercase;
}

@media only screen and (max-width: 47.999em) {
	/* line 156, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__caption-title--typing {
		font-size: 2.13544em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 156, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__caption-title--typing {
		font-size: 1.833em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 156, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__caption-title--typing {
		font-size: 1.833em;
	}
}

/* line 162, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods,
.m-carousel .carousel__expander-caption {
	position: absolute;
	bottom: 100%;
	left: 0;
	background: rgba(var(--color-global-negative), 0.5);
	z-index: 999;
	display: none;
	width: 100%;
}

/* line 174, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-scrollable {
	overflow: auto;
}

/* line 178, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__caption-status-icon {
	padding-left: 10px;
}

/* line 183, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel.is-neighbourhood-opened .carousel__caption-status-icon .icon-arrow-down-full {
	display: none;
}

/* line 186, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel.is-neighbourhood-opened .carousel__caption-status-icon .icon-arrow-up-full {
	display: inline-block;
}

/* line 191, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel:not(.is-neighbourhood-opened) .carousel__caption-status-icon .icon-arrow-down-full {
	display: inline-block;
}

/* line 194, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel:not(.is-neighbourhood-opened) .carousel__caption-status-icon .icon-arrow-up-full {
	display: none;
}

/* line 198, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods {
	color: rgb(var(--color-global-secondary));
	background: rgba(var(--color-global-positive), 0.95);
	display: block;
	width: 50%;
	bottom: 0;
	top: 0;
	padding-top: 0;
}

/* line 207, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods .carousel__neighbourhood-level[data-level="0"] > .carousel__location-item {
	width: 100%;
	border-bottom: 1px solid rgb(var(--color-global-helper));
	padding: 10px 40px;
	box-sizing: border-box;
}

/* line 213, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods .carousel__neighbourhood-level[data-level="0"] > .carousel__location-item > .carousel__location-label {
	font-size: 1.34em;
	cursor: pointer;
}

/* line 219, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods .carousel__neighbourhood-level:not([data-level="0"]) {
	overflow-y: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-bottom: 20px;
}

/* line 226, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods .carousel__neighbourhood-level:not([data-level="0"]) > li {
	width: 100%;
	padding: 10px 0 0 10px;
	box-sizing: border-box;
}

@media only screen and (min-width: 60em) {
	/* line 226, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__expander-neighborhoods .carousel__neighbourhood-level:not([data-level="0"]) > li {
		width: 100%;
	}
}

/* line 235, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods .carousel__neighbourhood-level:not([data-level="0"]) .carousel__location-label {
	font-size: 1.2em;
}

/* line 240, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods .carousel__location--has-child {
	position: relative;
}

/* line 243, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods .carousel__location--has-child .carousel__location-link {
	font-size: 1.2em;
}

/* line 247, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods .carousel__location--has-child > .carousel__location-label::before {
	content: "+";
	display: block;
	position: absolute;
	color: #333;
	font-size: 2rem;
	margin-left: -20px;
	line-height: 20px;
}

/* line 258, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods .carousel__location--has-child .carousel__location-label.checked::before {
	content: "-";
}

/* line 263, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-neighborhoods .js-location-tab:not(:checked) + label + ul {
	display: none;
}

@media only screen and (max-width: 47.999em) {
	/* line 269, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__expander-neighborhoods {
		width: 100%;
	}
}

/* line 274, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .expander__close-button {
	position: absolute;
	right: 10px;
	top: 40px;
	color: rgb(var(--color-global-border));
	cursor: pointer;
	font-size: 0.834em;
}

/* line 282, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .expander__close-button:hover {
	color: rgb(var(--color-global-positive));
}

/* line 287, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-wrapper {
	display: flex;
}

/* line 290, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-wrapper .expander__container {
	flex: 1;
	padding: 20px;
	box-sizing: border-box;
	padding-top: 60px;
}

/* line 296, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-wrapper .expander__container-title {
	font-size: 1.34em;
	font-weight: 600;
	color: rgb(var(--color-global-positive));
	white-space: break-spaces;
}

/* line 303, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-wrapper .expander__container-text {
	margin-top: 20px;
	color: rgb(var(--color-global-positive));
	font-weight: 600;
	font-size: 1.167em;
	white-space: break-spaces;
	overflow-y: auto;
}

/* line 311, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-wrapper .expander__container-text::-webkit-scrollbar-track {
	background-color: rgba(var(--m-carousel-details-text-color), 0.4);
}

/* line 315, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-wrapper .expander__container-text::-webkit-scrollbar-thumb {
	background-color: rgb(var(--color-global-border));
}

/* line 320, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__expander-wrapper .expander__container-map {
	position: relative;
}

/* line 326, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__details--scalable {
	overflow: initial;
}

/* line 329, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__details--scalable .carousel__details-container {
	display: flex;
	align-items: center;
}

/* line 334, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-text-container {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: rgb(var(--color-global-border));
	flex: 1;
}

/* line 340, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-text-container--title {
	font-family: var(--font-family-headings);
	vertical-align: sub;
}

@media only screen and (min-width: 48em) {
	/* line 340, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__details--scalable .carousel__caption-text-container--title {
		display: inline-block;
		max-width: 85%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 340, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__details--scalable .carousel__caption-text-container--title {
		display: inline-block;
		max-width: 92%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

/* line 357, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-icon {
	padding-right: 10px;
	color: rgb(var(--color-global-border));
	font-size: 2rem;
	flex: 0;
	cursor: pointer;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-carousel .carousel__details--scalable .carousel__caption-icon {
	padding-right: initial;
	padding-left: 10px;
}

/* line 365, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-icon:hover {
	color: rgb(var(--color-global-positive));
}

/* line 369, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-icon--anchor {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: rgb(var(--color-global-border));
	font-size: 2rem;
}

/* line 375, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-icon--anchor:hover {
	color: rgb(var(--color-global-positive));
}

@media only screen and (max-width: 59.999em) {
	/* line 381, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__details--scalable .carousel__caption-icon--marketdata {
		white-space: normal;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 389, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__expander-caption {
		padding-top: 20px;
	}
	/* line 393, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .expander__container--description {
		display: none;
	}
	/* line 397, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__expander-wrapper {
		flex-direction: column;
	}
	/* line 400, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__expander-wrapper .expander__close-button {
		top: 10px;
	}
	/* line 404, scss/70-modules/homepageimagecarousel/_module.scss */
	.m-carousel .carousel__expander-wrapper .expander__container {
		padding: 5px;
	}
}

/* line 412, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__slide-title-container {
	color: rgb(var(--color-global-positive));
}

/* line 416, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__slide-link {
	color: rgb(var(--color-global-positive));
	border-color: rgb(var(--color-global-positive));
}

/* line 420, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__slide-link-container {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__slide-link-container {
	text-align: left;
}

/* line 425, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__slide-link-container,
.carousel__slide-location-list {
	margin-top: 20px;
}

/* line 430, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__slide-title-location,
.carousel__location-item {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: block;
}

/* line 438, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__slide-title-location {
	display: block;
}

/* line 442, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__location-wrap {
	padding: 0 20px 0 20px;
	text-align: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__location-wrap {
	padding: 0 20px 0 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__location-wrap {
	text-align: right;
}

/* line 447, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__slide-location-list {
	margin-bottom: 20px;
}

/* line 451, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__ar-container {
	min-height: 300px;
}

/* line 454, scss/70-modules/homepageimagecarousel/_module.scss */
.is-fullscreen .carousel__ar-container {
	height: 100vh !important;
}

/* line 459, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__wrapper {
	width: 100%;
	position: relative;
	overflow: hidden;
	user-select: none;
	z-index: 2;
	height: 100%;
	display: block;
	background-color: rgb(var(--color-global-positive));
	box-sizing: border-box;
}

/* line 476, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__track {
	position: relative;
	width: 100%;
	height: 100%;
	transition: margin .25s linear;
}

/* line 483, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__container {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 2;
	transition: all .25s linear;
}

/* line 489, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__track .carousel__container {
	position: relative;
}

@media only screen and (min-width: 80em) {
	/* line 483, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__container {
		width: 76%;
		left: 50%;
		transform: translate(-50%, -0);
	}
}

@media only screen and (min-width: 105em) {
	/* line 483, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__container {
		width: 60%;
	}
	/* line 504, scss/70-modules/homepageimagecarousel/_module.scss */
	.with-one-wing .carousel__container {
		width: 76%;
	}
}

/* line 509, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__container .vjs-control-bar {
	display: none;
}

/* line 513, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__container .carousel__expand-src {
	left: -99999px;
	position: fixed;
	top: -99999px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__container .carousel__expand-src {
	left: auto;
	right: -99999px;
}

/* line 520, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__link {
	color: rgb(var(--color-global-border));
	display: block;
	height: 100%;
	width: 100%;
	transform: rotate(0);
}

/* line 528, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__link:hover {
	color: rgb(var(--color-global-border));
}

/* line 535, scss/70-modules/homepageimagecarousel/_module.scss */
.is-center {
	margin-left: 0;
	z-index: 10;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-center {
	margin-left: initial;
	margin-right: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-center {
		margin-left: 0;
	}
}

/* line 540, scss/70-modules/homepageimagecarousel/_module.scss */
.is-center--fade {
	opacity: 1;
}

/* line 544, scss/70-modules/homepageimagecarousel/_module.scss */
.is-far-left {
	margin-left: -200%;
	top: -20px;
	z-index: 4;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-far-left {
	margin-left: initial;
	margin-right: -200%;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-far-left {
		margin-left: 0;
	}
}

/* line 549, scss/70-modules/homepageimagecarousel/_module.scss */
.is-far-left .carousel__item {
	opacity: 0.6;
}

@media only screen and (min-width: 80em) {
	/* line 544, scss/70-modules/homepageimagecarousel/_module.scss */
	.is-far-left {
		margin-left: -100%;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-far-left {
		margin-left: initial;
		margin-right: -100%;
	}
}

@media only screen and (min-width: 80em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-far-left {
		margin-left: 0;
	}
}

@media only screen and (min-width: 105em) {
	/* line 544, scss/70-modules/homepageimagecarousel/_module.scss */
	.is-far-left {
		margin-left: -20%;
		top: -40px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-far-left {
		margin-left: initial;
		margin-right: -20%;
	}
}

@media only screen and (min-width: 105em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-far-left {
		margin-left: 0;
	}
}

@media only screen and (min-width: 105em) {
	/* line 561, scss/70-modules/homepageimagecarousel/_module.scss */
	.with-one-wing .is-far-left {
		margin-left: -100%;
		top: -20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .with-one-wing .is-far-left {
		margin-left: initial;
		margin-right: -100%;
	}
}

@media only screen and (min-width: 105em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .with-one-wing .is-far-left {
		margin-left: 0;
	}
}

/* line 567, scss/70-modules/homepageimagecarousel/_module.scss */
.is-far-left--fade {
	z-index: 1;
	opacity: 0;
}

/* line 574, scss/70-modules/homepageimagecarousel/_module.scss */
.is-next-left {
	margin-left: -100%;
	z-index: 7;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-next-left {
	margin-left: initial;
	margin-right: -100%;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-next-left {
		margin-left: 0;
	}
}

/* line 578, scss/70-modules/homepageimagecarousel/_module.scss */
.is-next-left .carousel__item {
	opacity: 0.8;
}

@media only screen and (min-width: 80em) {
	/* line 574, scss/70-modules/homepageimagecarousel/_module.scss */
	.is-next-left {
		margin-left: -12%;
		top: -20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-next-left {
		margin-left: initial;
		margin-right: -12%;
	}
}

@media only screen and (min-width: 80em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-next-left {
		margin-left: 0;
	}
}

@media only screen and (min-width: 105em) {
	/* line 574, scss/70-modules/homepageimagecarousel/_module.scss */
	.is-next-left {
		margin-left: -10%;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-next-left {
		margin-left: initial;
		margin-right: -10%;
	}
}

@media only screen and (min-width: 105em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-next-left {
		margin-left: 0;
	}
}

@media only screen and (min-width: 105em) {
	/* line 590, scss/70-modules/homepageimagecarousel/_module.scss */
	.with-one-wing .is-next-left {
		margin-left: -12%;
		top: -20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .with-one-wing .is-next-left {
		margin-left: initial;
		margin-right: -12%;
	}
}

@media only screen and (min-width: 105em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .with-one-wing .is-next-left {
		margin-left: 0;
	}
}

/* line 596, scss/70-modules/homepageimagecarousel/_module.scss */
.is-next-left--fade {
	z-index: 1;
	opacity: 0;
}

/* line 603, scss/70-modules/homepageimagecarousel/_module.scss */
.is-next-right {
	margin-left: 100%;
	z-index: 7;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-next-right {
	margin-left: initial;
	margin-right: 100%;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-next-right {
		margin-left: 0;
	}
}

/* line 607, scss/70-modules/homepageimagecarousel/_module.scss */
.is-next-right .carousel__item {
	opacity: 0.8;
}

@media only screen and (min-width: 80em) {
	/* line 603, scss/70-modules/homepageimagecarousel/_module.scss */
	.is-next-right {
		margin-left: 12%;
		top: -20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-next-right {
		margin-left: initial;
		margin-right: 12%;
	}
}

@media only screen and (min-width: 80em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-next-right {
		margin-left: 0;
	}
}

@media only screen and (min-width: 105em) {
	/* line 603, scss/70-modules/homepageimagecarousel/_module.scss */
	.is-next-right {
		margin-left: 10%;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-next-right {
		margin-left: initial;
		margin-right: 10%;
	}
}

@media only screen and (min-width: 105em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-next-right {
		margin-left: 0;
	}
}

@media only screen and (min-width: 105em) {
	/* line 619, scss/70-modules/homepageimagecarousel/_module.scss */
	.with-one-wing .is-next-right {
		margin-left: 12%;
		top: -20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .with-one-wing .is-next-right {
		margin-left: initial;
		margin-right: 12%;
	}
}

@media only screen and (min-width: 105em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .with-one-wing .is-next-right {
		margin-left: 0;
	}
}

/* line 625, scss/70-modules/homepageimagecarousel/_module.scss */
.is-next-right--fade {
	z-index: 1;
	opacity: 0;
}

/* line 632, scss/70-modules/homepageimagecarousel/_module.scss */
.is-far-right {
	margin-left: 200%;
	top: -20px;
	z-index: 4;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-far-right {
	margin-left: initial;
	margin-right: 200%;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-far-right {
		margin-left: 0;
	}
}

/* line 637, scss/70-modules/homepageimagecarousel/_module.scss */
.is-far-right .carousel__item {
	opacity: 0.6;
}

@media only screen and (min-width: 80em) {
	/* line 632, scss/70-modules/homepageimagecarousel/_module.scss */
	.is-far-right {
		margin-left: 100%;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-far-right {
		margin-left: initial;
		margin-right: 100%;
	}
}

@media only screen and (min-width: 80em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-far-right {
		margin-left: 0;
	}
}

@media only screen and (min-width: 105em) {
	/* line 632, scss/70-modules/homepageimagecarousel/_module.scss */
	.is-far-right {
		margin-left: 20%;
		top: -40px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-far-right {
		margin-left: initial;
		margin-right: 20%;
	}
}

@media only screen and (min-width: 105em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-far-right {
		margin-left: 0;
	}
}

@media only screen and (min-width: 105em) {
	/* line 650, scss/70-modules/homepageimagecarousel/_module.scss */
	.with-one-wing .is-far-right {
		margin-left: 100%;
		top: -20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .with-one-wing .is-far-right {
		margin-left: initial;
		margin-right: 100%;
	}
}

@media only screen and (min-width: 105em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .with-one-wing .is-far-right {
		margin-left: 0;
	}
}

/* line 656, scss/70-modules/homepageimagecarousel/_module.scss */
.is-far-right--fade {
	z-index: 1;
	opacity: 0;
}

/* line 663, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details {
	z-index: 999;
	right: 0;
	position: absolute;
	width: auto;
	max-width: 100%;
	bottom: 0;
	padding: 10px 20px;
	color: rgb(var(--color-global-border));
	box-sizing: border-box;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.5);
	border-top: 1px solid rgba(0, 0, 0, 0.5);
	font-size: 1.3rem;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details {
	right: auto;
	left: 0;
}

/* line 684, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--full {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
}

/* line 692, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--top {
	top: 0;
	left: 0;
	right: 0;
	bottom: auto;
	width: 100%;
}

/* line 700, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--top-left {
	left: 0;
	right: auto;
	top: 0;
	bottom: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--top-left {
	left: auto;
	right: 0;
}

/* line 706, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--top-right {
	left: auto;
	right: 0;
	top: 0;
	bottom: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--top-right {
	left: 0;
	right: auto;
}

/* line 712, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--bottom {
	top: auto;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
}

/* line 720, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--bottom-left {
	left: 0;
	right: auto;
	top: auto;
	bottom: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--bottom-left {
	left: auto;
	right: 0;
}

/* line 726, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--bottom-right {
	left: auto;
	right: 0;
	top: auto;
	bottom: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--bottom-right {
	left: 0;
	right: auto;
}

/* line 732, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--full-left {
	left: 0;
	right: auto;
	top: 0;
	bottom: 0;
	width: 50%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--full-left {
	left: auto;
	right: 0;
}

/* line 739, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--full-right {
	left: auto;
	right: 0;
	top: 0;
	bottom: 0;
	width: 30%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--full-right {
	left: 0;
	right: auto;
}

@media only screen and (max-width: 47.999em) {
	/* line 739, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details--full-right {
		width: 100%;
		top: auto;
	}
}

/* line 751, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--above {
	width: 100%;
	margin-bottom: 10px;
}

/* line 756, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--below {
	margin-top: 10px;
	width: 100%;
}

/* line 761, scss/70-modules/homepageimagecarousel/_module.scss */
.is-center .carousel__details {
	opacity: 1;
	transition: all 0.5s linear;
}

/* line 766, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details .slide__info-title {
	font-size: 1.2em;
	font-weight: bold;
	margin-bottom: 10px;
}

/* line 772, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details .slide__info-text {
	margin-bottom: 10px;
}

/* line 776, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details .slide__info-button {
	margin-bottom: 10px;
}

/* line 780, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__track .carousel__details {
	opacity: 1;
}

/* line 784, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details .carousel__details-container {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background-color: transparent;
	font-size: 1.34rem;
}

@media only screen and (max-width: 47.999em) {
	/* line 784, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details .carousel__details-container {
		font-size: 1.0519rem;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 784, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details .carousel__details-container {
		font-size: 1.14838rem;
	}
}

@media only screen and (min-width: 80em) {
	/* line 784, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details .carousel__details-container {
		font-size: 1.34rem;
	}
}

/* line 796, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details .carousel__details-container.is-vertical-aligned-middle {
	position: absolute;
	top: 50%;
	transform: translate(-0, -50%);
	left: 20px;
	right: 20px;
}

@media only screen and (max-width: 47.999em) {
	/* line 796, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details .carousel__details-container.is-vertical-aligned-middle {
		position: relative;
		transform: none;
	}
}

/* line 808, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details .carousel__details-container.is-vertical-aligned-bottom {
	position: absolute;
	bottom: 20px;
}

/* line 814, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details.carousel__video-details {
	text-align: left;
	padding: 10px 10px 0 10px;
	background-color: rgb(var(--color-global-primary));
	border: none;
	overflow: initial;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details.carousel__video-details {
	text-align: right;
}

/* line 821, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details.carousel__video-details.is-current {
	position: relative;
}

/* line 825, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details.carousel__video-details .slide__info-title {
	font-size: 1.083em;
	line-height: 1em;
	color: rgb(var(--color-global-secondary));
	text-transform: uppercase;
	display: block;
}

/* line 833, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details.carousel__video-details .carousel__action-social {
	color: rgb(var(--color-global-secondary));
	font-size: 1.05em;
	cursor: pointer;
}

/* line 839, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details.carousel__video-details .carousel__social-container {
	right: 0;
	position: absolute;
	display: none;
	top: 100%;
	width: 100px;
	box-shadow: 0 0 10px rgba(var(--color-global-border), 0.2);
	background-color: rgb(var(--color-global-border));
	margin-top: -10px;
	padding-bottom: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details.carousel__video-details .carousel__social-container {
	right: auto;
	left: 0;
}

/* line 850, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details.carousel__video-details .carousel__social-container.is-open {
	display: block;
}

/* line 854, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details.carousel__video-details .carousel__social-container iframe {
	display: block;
}

/* line 861, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details-more-link {
	display: block;
	color: rgb(var(--color-global-positive));
}

/* line 865, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details-more-link:hover {
	color: rgb(var(--color-global-positive));
}

/* line 869, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details-more-link--typing {
	font-size: 1.083em;
	margin-top: 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 869, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details-more-link--typing {
		font-size: 1.2617em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 869, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details-more-link--typing {
		font-size: 1.083em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 869, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details-more-link--typing {
		font-size: 1.083em;
	}
}

/* line 874, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details-more-link-container {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details-more-link-container {
	text-align: left;
}

/* line 879, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details-more-arrow {
	border-left: 5px solid rgb(var(--color-global-positive));
	margin-left: 5px;
	display: inline-block;
	vertical-align: middle;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details-more-arrow {
	border-left: 0;
	border-right: 5px solid rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details-more-arrow {
	margin-left: initial;
	margin-right: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__details-more-arrow {
		margin-left: 0;
	}
}

/* line 890, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__caption-separator {
	display: inline;
}

/* line 894, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__caption-text {
	margin: 10px 0;
	white-space: normal;
}

/* line 899, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__slide-location-list {
	overflow: hidden;
}

/* line 903, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable {
	left: 10px;
	max-width: 320px;
	background: none;
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--expandable {
	left: auto;
	right: 10px;
}

/* line 909, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable.carousel__details {
	height: auto;
	top: auto !important;
	bottom: 10px;
	padding: 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 909, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details--expandable.carousel__details {
		max-width: calc(100% - 20px);
		width: 100%;
	}
}

/* line 921, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable.is-expanded {
	background: rgb(var(--color-global-positive));
	transition: all .5s;
}

/* line 925, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable.is-expanded .carousel__caption-details {
	display: block;
}

/* line 930, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable.is-expanded .carousel__caption-title:after {
	border-bottom: 6px solid rgb(var(--color-global-secondary));
	border-top: 0;
}

/* line 936, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable.is-expanded .carousel__caption-text,
.carousel__details--expandable.is-expanded .carousel__caption-title {
	color: rgb(var(--color-global-secondary));
}

/* line 942, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable .carousel__caption-title {
	font-size: 1.167em;
	text-align: left;
	padding-right: 20px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: block;
	line-height: 1.4;
	margin-bottom: 0;
	position: relative;
	color: rgb(var(--color-global-positive));
}

@media only screen and (max-width: 47.999em) {
	/* line 942, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details--expandable .carousel__caption-title {
		font-size: 1.35956em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 942, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details--expandable .carousel__caption-title {
		font-size: 1.167em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 942, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details--expandable .carousel__caption-title {
		font-size: 1.167em;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--expandable .carousel__caption-title {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--expandable .carousel__caption-title {
	padding-right: initial;
	padding-left: 20px;
}

/* line 955, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable .carousel__caption-title:after {
	transform: translate(0, -50%);
	right: 0;
	position: absolute;
	top: 50%;
	cursor: pointer;
	content: '';
	border-top: 6px solid rgb(var(--color-global-positive));
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--expandable .carousel__caption-title:after {
	right: auto;
	left: 0;
}

/* line 968, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable .carousel__caption-details {
	text-align: left;
	display: none;
	max-height: 150px;
	overflow-y: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--expandable .carousel__caption-details {
	text-align: right;
}

/* line 975, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable .carousel__caption-price {
	margin-right: 5px;
	background: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-border));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__details--expandable .carousel__caption-price {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__details--expandable .carousel__caption-price {
		margin-right: 0;
	}
}

/* line 981, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable .carousel__caption-price,
.carousel__details--expandable .carousel__details-more-link {
	font-size: 1.083em;
	padding: 10px 20px;
	display: inline-block;
}

@media only screen and (max-width: 47.999em) {
	/* line 981, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details--expandable .carousel__caption-price,
	.carousel__details--expandable .carousel__details-more-link {
		font-size: 1.2617em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 981, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details--expandable .carousel__caption-price,
	.carousel__details--expandable .carousel__details-more-link {
		font-size: 1.083em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 981, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details--expandable .carousel__caption-price,
	.carousel__details--expandable .carousel__details-more-link {
		font-size: 1.083em;
	}
}

/* line 988, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__details--expandable .carousel__details-more-link {
	background: rgb(var(--color-global-secondary));
}

@media only screen and (max-width: 79.999em) {
	/* line 992, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__details--expandable .carousel__caption-button-wrapper {
		display: none;
	}
}

/* line 999, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__property-details {
	font-family: var(--font-family-headings);
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	backface-visibility: hidden;
	z-index: 999;
	right: 20px;
	font-family: var(--font-family-headings);
	font-style: italic;
	color: rgb(var(--color-global-positive));
	padding: 5px 10px;
	border: 1px solid rgb(var(--color-global-positive));
	position: absolute;
	top: 20px;
	visibility: hidden;
	opacity: 0;
	transition: opacity .25s linear;
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 999, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__property-details {
		font-size: 1.2em;
		line-height: 1.1817;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 999, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__property-details {
		font-size: 1.2em;
		line-height: 1.3;
	}
}

@media only screen and (min-width: 80em) {
	/* line 999, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__property-details {
		font-size: 1.2em;
		line-height: 1.3;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__property-details {
	right: auto;
	left: 20px;
}

/* line 1016, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__property-details:hover {
	color: rgb(var(--color-global-positive));
}

/* line 1020, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel:hover .is-center .carousel__property-details {
	display: inline-block;
	visibility: visible;
	opacity: 1;
}

/* line 1027, scss/70-modules/homepageimagecarousel/_module.scss */
.slide__info-text {
	font-size: 1em;
	color: rgb(var(--color-global-secondary));
	text-transform: uppercase;
	display: block;
}

/* line 1034, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls {
	z-index: 999;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	box-sizing: border-box;
	transition: all .25s linear;
}

/* line 1046, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel:not(.carousel--is-touch) .carousel__video-controls {
	opacity: 0;
}

/* line 1050, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel:not(.carousel--is-touch) .carousel__video-controls:hover, .m-carousel:not(.carousel--is-touch) .carousel__video-controls:focus {
	opacity: 1;
}

/* line 1056, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel--is-inactive:not(.carousel--is-touch) .carousel__video-controls {
	opacity: 0;
}

/* line 1060, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel--is-inactive:not(.carousel--is-touch) .carousel__video-controls:hover {
	opacity: 0;
}

/* line 1066, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel--is-touch.carousel--is-playing .carousel__video-controls {
	opacity: .7;
}

/* line 1071, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel--is-touch:not(.carousel--is-playing) .carousel__video-controls {
	opacity: 1;
}

/* line 1076, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel--is-touch.carousel--is-playing .carousel__video-controls.carousel__video-controls--center,
.carousel--is-touch.carousel--is-waiting .carousel__video-controls.carousel__video-controls--center {
	opacity: 1;
}

/* line 1082, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls__control {
	color: rgb(var(--color-global-positive));
	background-color: rgba(var(--color-global-negative), 0.5);
	cursor: pointer;
	transition: background-color .25s linear;
}

/* line 1088, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls__control:hover {
	background-color: rgba(var(--color-global-negative), 0.7);
}

/* line 1092, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls__control.hide-pause.icon-pause {
	opacity: 0;
}

/* line 1096, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls__control__play {
	border-radius: 50%;
	font-size: 16px;
	position: absolute;
	display: inline-block;
	line-height: normal;
}

/* line 1097, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls__control__play:not(.o-loader) {
	padding: 5px;
}

/* line 1107, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls--bottom-right .carousel__video-controls__control__play {
	left: auto;
	right: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__video-controls--bottom-right .carousel__video-controls__control__play {
	left: 20px;
	right: auto;
}

/* line 1111, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls--bottom-left .carousel__video-controls__control__play {
	left: 20px;
	right: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__video-controls--bottom-left .carousel__video-controls__control__play {
	left: auto;
	right: 20px;
}

/* line 1115, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls--bottom-right .carousel__video-controls__control__play,
.carousel__video-controls--bottom-left .carousel__video-controls__control__play {
	bottom: 65px;
	top: auto;
}

/* line 1121, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls--center .carousel__video-controls__control__play {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: center center;
	transition: background-color .25s linear;
}

/* line 1122, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-controls--center .carousel__video-controls__control__play:not(.o-loader) {
	padding: 5px;
}

/* line 1135, scss/70-modules/homepageimagecarousel/_module.scss */
.video-player-wrapper.is-fullscreen {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

/* line 1144, scss/70-modules/homepageimagecarousel/_module.scss */
.vjs-control-bar {
	text-indent: -9999px;
}

/* line 1148, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__nav {
	position: relative;
}

/* line 1153, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__action--left {
	left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__action--left {
	left: auto;
	right: 0;
}

/* line 1157, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__action--right {
	right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__action--right {
	right: auto;
	left: 0;
}

/* line 1163, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single .is-far-left {
	margin-left: -200%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single .is-far-left {
	margin-left: initial;
	margin-right: -200%;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-single .is-far-left {
		margin-left: 0;
	}
}

/* line 1167, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single .is-next-left {
	margin-left: -100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single .is-next-left {
	margin-left: initial;
	margin-right: -100%;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-single .is-next-left {
		margin-left: 0;
	}
}

/* line 1171, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single .is-next-right {
	margin-left: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single .is-next-right {
	margin-left: initial;
	margin-right: 100%;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-single .is-next-right {
		margin-left: 0;
	}
}

/* line 1175, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single .is-far-right {
	margin-left: 200%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single .is-far-right {
	margin-left: initial;
	margin-right: 200%;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .is-single .is-far-right {
		margin-left: 0;
	}
}

/* line 1179, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single .carousel__container:not(.is-hidden-slide) {
	top: 0;
}

/* line 1183, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single .carousel__container {
	transform: translate(0, 0);
	left: 0;
	width: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .is-single .carousel__container {
	left: auto;
	right: 0;
}

/* line 1190, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullets {
	z-index: 99;
	position: absolute;
	list-style: none;
	text-align: center;
	bottom: 50px;
	left: 0;
	right: 0;
}

@media only screen and (min-width: 80em) {
	/* line 1190, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__bullets {
		padding: 0 13%;
	}
}

@media only screen and (min-width: 105em) {
	/* line 1190, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__bullets {
		padding: 0 21%;
	}
}

/* line 1207, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single .carousel__bullets {
	padding: 0;
}

/* line 1211, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullets.carousel__bullets--position-top-left, .carousel__bullets.carousel__bullets--position-top-right, .carousel__bullets.carousel__bullets--position-bottom-left, .carousel__bullets.carousel__bullets--position-bottom-right {
	padding: 0;
}

/* line 1218, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullets.carousel__bullets--position-bottom-left, .carousel__bullets.carousel__bullets--position-bottom-left {
	text-align: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__bullets.carousel__bullets--position-bottom-left, .is-rtl .carousel__bullets.carousel__bullets--position-bottom-left {
	text-align: right;
}

/* line 1223, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullets.carousel__bullets--position-bottom-right, .carousel__bullets.carousel__bullets--position-bottom-right {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__bullets.carousel__bullets--position-bottom-right, .is-rtl .carousel__bullets.carousel__bullets--position-bottom-right {
	text-align: left;
}

/* line 1228, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullets.carousel__bullets--position-bottom-left {
	left: 20px;
	right: auto;
	bottom: 20px;
	top: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__bullets.carousel__bullets--position-bottom-left {
	left: auto;
	right: 20px;
}

/* line 1234, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullets.carousel__bullets--position-bottom-right {
	left: auto;
	right: 20px;
	bottom: 20px;
	top: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__bullets.carousel__bullets--position-bottom-right {
	left: 20px;
	right: auto;
}

/* line 1240, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullets.carousel__bullets--position-top-left {
	left: 20px;
	right: auto;
	top: 20px;
	bottom: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__bullets.carousel__bullets--position-top-left {
	left: auto;
	right: 20px;
}

/* line 1246, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullets.carousel__bullets--position-top-right {
	left: auto;
	right: 20px;
	top: 20px;
	bottom: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__bullets.carousel__bullets--position-top-right {
	left: 20px;
	right: auto;
}

/* line 1253, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullet {
	display: inline-block;
	cursor: pointer;
	vertical-align: middle;
}

/* line 1258, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullet::after {
	border-radius: 100%;
	display: block;
	position: relative;
	content: "";
	background-color: rgb(var(--color-global-positive));
	width: 6px;
	height: 6px;
	box-shadow: 0 0 3px rgba(var(--m-carousel-bullets-shadow), 0.75);
	transition: all 1000ms;
	margin: 0 5px;
}

/* line 1270, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullets--vertical .carousel__bullet::after {
	margin: 10px auto;
}

/* line 1275, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullet.is-active::after {
	width: 12px;
	height: 12px;
	background-color: rgb(var(--color-global-positive));
}

/* line 1281, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullet.is-current::after {
	right: 3px;
	position: relative;
	width: 12px;
	height: 12px;
	background-color: rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__bullet.is-current::after {
	right: auto;
	left: 3px;
}

@media only screen and (min-width: 48em) {
	/* line 1289, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__bullets--vertical .carousel__bullet {
		display: block;
		text-align: center;
	}
}

/* line 1297, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullet.carousel__bullet--hidden::after {
	width: 0;
	margin: 0;
}

/* line 1301, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__bullets--vertical .carousel__bullet.carousel__bullet--hidden::after {
	height: 0;
	margin: 0 auto;
}

/* line 1307, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__clear {
	font-size: 0;
	line-height: 0;
	height: 0;
	margin-bottom: 20px;
}

/* line 1315, scss/70-modules/homepageimagecarousel/_module.scss */
.m-freeformhtml.carousel-caption-wrapper {
	margin-bottom: 0;
}

/* line 1319, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__caption--custom {
	z-index: 0;
}

/* line 1322, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__caption--custom.is-current {
	opacity: 1;
	z-index: 9;
}

/* line 1330, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__video-player--created {
	z-index: 800;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

/* line 1339, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single .carousel__video-player--created .video-player-poster {
	display: none;
}

/* line 1350, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__body-overlay {
	z-index: 999;
	background-color: rgba(var(--m-carousel-color-dark), 0.7);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

/* line 1360, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__play--center {
	position: absolute;
	font-size: 3em;
	color: rgb(var(--color-global-positive));
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
}

/* line 1368, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__expander-controls {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
	height: 40px;
	background: rgba(var(--m-carousel-color-dark), 0.7);
	font-size: 26px;
	color: rgb(var(--color-global-positive));
}

/* line 1380, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__expander-controls .carousel__play {
	border-right: 1px solid rgb(var(--color-global-positive));
	left: 0;
	border-radius: 0;
	width: 50px;
	height: 30px;
	display: block;
	position: absolute;
	text-align: center;
	padding: 5px 0;
	cursor: pointer;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__expander-controls .carousel__play {
	border-right: 0;
	border-left: 1px solid rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__expander-controls .carousel__play {
	left: auto;
	right: 0;
}

/* line 1392, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__expander-controls .carousel__play:hover {
	font-size: 28px;
}

/* line 1397, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__expander-controls .carousel__expand {
	right: 0;
	border-left: 1px solid rgb(var(--color-global-positive));
	width: 50px;
	height: 30px;
	display: block;
	position: absolute;
	text-align: center;
	padding: 5px 0;
	cursor: pointer;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__expander-controls .carousel__expand {
	right: auto;
	left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__expander-controls .carousel__expand {
	border-left: 0;
	border-right: 1px solid rgb(var(--color-global-positive));
}

/* line 1408, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__expander-controls .carousel__expand:hover {
	font-size: 28px;
}

/* line 1414, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__expander {
	width: 90%;
	height: 90%;
	max-width: 90%;
	max-height: 90%;
	position: fixed;
	z-index: 9999;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* line 1424, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__expander .vjs-control-bar {
	display: none;
}

/* line 1429, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__expander-media-video,
.carousel__expander-media-image,
.carousel__expander-media-iframe {
	box-sizing: border-box;
	padding: 10px;
	background: rgba(var(--m-carousel-color-dark), 0.7);
}

/* line 1437, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__close-expander {
	right: 10px;
	font-size: 16px;
	width: 24px;
	height: 24px;
	line-height: 24px;
	color: rgb(var(--color-global-positive));
	font-weight: bolder;
	position: absolute;
	top: 10px;
	z-index: 999;
	transition: all 0.2s ease-in-out;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__close-expander {
	right: auto;
	left: 10px;
}

/* line 1451, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__close-expander:hover {
	transform: rotate(90deg);
	right: 14px;
	top: 14px;
	cursor: pointer;
	color: rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__close-expander:hover {
	right: auto;
	left: 14px;
}

/* line 1460, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__close-expander.is-outside {
	right: -35px;
	top: -25px;
	font-size: 2em;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__close-expander.is-outside {
	right: auto;
	left: -35px;
}

/* line 1465, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__close-expander.is-outside:hover {
	transform: rotate(0deg);
	right: -35px;
	top: -25px;
	cursor: pointer;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__close-expander.is-outside:hover {
	right: auto;
	left: -35px;
}

/* line 1474, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__navigation-caption-container {
	overflow: visible;
	min-width: 3%;
}

@media only screen and (max-width: 47.999em) {
	/* line 1474, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container {
		position: absolute;
		bottom: 20px;
		left: 0;
		right: 0;
		padding-bottom: 50px;
		box-sizing: border-box;
	}
	/* line 1486, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__titles-container,
	.carousel__navigation-caption-container .carousel__locations-container {
		display: none;
	}
	/* line 1491, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__details {
		text-overflow: initial;
		white-space: normal;
		background-color: transparent;
		text-align: center;
		left: 0;
		right: 0;
	}
	/* line 1500, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__bullets {
		bottom: 0;
	}
	/* line 1504, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__bullet.is-active:after {
		background-color: rgb(var(--color-global-secondary));
		width: 6px;
		height: 6px;
		top: 0;
	}
}

@media only screen and (min-width: 48em) and (max-width: 59.999em) {
	/* line 1474, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container {
		width: 75%;
	}
}

@media only screen and (min-width: 48em) {
	/* line 1474, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container {
		right: 40px;
		z-index: 999;
		position: absolute;
		bottom: 80px;
		width: 60%;
		color: rgb(var(--color-global-positive));
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__navigation-caption-container {
		right: auto;
		left: 40px;
	}
	/* line 1525, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .is-current.carousel__location-wrap {
		border-left: 2px solid rgb(var(--color-global-positive));
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__navigation-caption-container .is-current.carousel__location-wrap {
		border-left: 0;
		border-right: 2px solid rgb(var(--color-global-positive));
	}
	/* line 1529, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .is-current .carousel__location-item {
		font-size: 20px;
		border-top: 1px solid rgb(var(--color-global-positive));
		border-bottom: 1px solid rgb(var(--color-global-positive));
		margin: 0;
		padding: 10px 0;
	}
	/* line 1538, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__location-item {
		margin: 10px 0;
		font-size: 15px;
		color: rgb(var(--color-global-positive));
	}
	/* line 1544, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .imagenav__action.imagenav__action--prev {
		transform: rotate(135deg);
		left: 0;
		right: auto;
		bottom: 0;
		top: auto;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__navigation-caption-container .imagenav__action.imagenav__action--prev {
		left: auto;
		right: 0;
	}
	/* line 1551, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .imagenav__action.imagenav__action--next {
		transform: rotate(-45deg);
		left: 0;
		right: auto;
		bottom: auto;
		top: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__navigation-caption-container .imagenav__action.imagenav__action--next {
		left: auto;
		right: 0;
	}
	/* line 1558, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__bullets {
		padding: 0;
		position: static;
		vertical-align: middle;
	}
	/* line 1564, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .imagenav--simple {
		width: 60px;
		height: 40px;
		background: transparent;
	}
	/* line 1570, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__action.carousel__action--left {
		top: -40px;
		bottom: auto;
		left: 50%;
		transform: translateX(-50%);
	}
	/* line 1577, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__action.carousel__action--right {
		left: 50%;
		right: auto;
		transform: translateX(-50%);
		top: auto;
		bottom: -40px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__navigation-caption-container .carousel__action.carousel__action--right {
		left: auto;
		right: 50%;
	}
	/* line 1585, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__slide-title:not(.is-current) {
		display: none;
	}
	/* line 1590, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__locations-container {
		width: 25%;
		max-width: 35%;
		position: relative;
	}
	/* line 1596, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__titles-container {
		min-width: 40%;
		max-width: 55%;
	}
	/* line 1601, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__bullets-container {
		width: 6px;
		padding: 0;
	}
	/* line 1606, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__bullet:after {
		background-color: transparent;
		border: 1px solid rgb(var(--color-global-positive));
	}
	/* line 1611, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .carousel__bullet.is-active:after {
		background-color: rgb(var(--color-global-positive));
		width: 6px;
		height: 6px;
		top: 0;
	}
	/* line 1618, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__navigation-caption-container .imagenav--simple:hover {
		background-color: transparent;
	}
}

/* line 1623, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__navigation-caption-container .carousel__slide-title-link {
	color: rgb(var(--color-global-positive));
}

/* line 1626, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__navigation-caption-container .carousel__slide-title-link:hover {
	color: rgb(var(--color-global-positive));
}

/* line 1631, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__navigation-caption-container .carousel__titles-container {
	box-shadow: -20px 0px 50px 40px rgba(0, 0, 0, 0.5);
	background-color: rgba(0, 0, 0, 0.5);
}

/* line 1636, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__navigation-caption-container .carousel__locations-container {
	box-shadow: 20px 0px 50px 40px rgba(0, 0, 0, 0.5);
	background-color: rgba(0, 0, 0, 0.5);
}

/* line 1642, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__navigation-caption,
.carousel__titles-container {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__navigation-caption, .is-rtl
.carousel__titles-container {
	text-align: left;
}

/* line 1647, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__titles-container,
.carousel__locations-container,
.carousel__bullets-container {
	vertical-align: middle;
}

/* line 1653, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__titles-container {
	margin-right: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__titles-container {
	margin-right: initial;
	margin-left: 20px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__titles-container {
		margin-right: 0;
	}
}

/* line 1657, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__locations-container {
	border-left: 1px solid rgb(var(--color-global-border));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__locations-container {
	border-left: 0;
	border-right: 1px solid rgb(var(--color-global-border));
}

/* line 1661, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel--vertical-navigation {
	position: relative;
}

/* line 1664, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel--vertical-navigation .u-ar {
	height: 100%;
}

/* line 1669, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel--vertical-navigation .carousel__location-wrap.is-current .carousel__slide-title-location {
	font-weight: bold;
}

/* line 1673, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel--vertical-navigation .carousel__location-wrap:not(.is-current) .carousel__slide-title-state {
	display: none;
}

/* line 1678, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel--vertical-navigation .carousel__details {
	display: none;
}

/* line 1683, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__overlay {
	pointer-events: none;
}

/* line 1689, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .carousel__details {
	transition: all 0.3s;
}

/* line 1694, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .carousel__caption-title, .is-single--detailsanimation .carousel__caption-subtitle, .is-single--detailsanimation .carousel__caption-location, .is-single--detailsanimation .carousel__caption-price, .is-single--detailsanimation .carousel__caption-text {
	margin-top: 40px;
	position: relative;
	opacity: 0;
	transition: opacity 0.5s, margin-top 0.5s;
}

/* line 1705, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .carousel__caption-title {
	top: 40px;
	transition: opacity 0.5s, top 0.5s;
	transition-delay: 0.8s;
}

/* line 1711, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .carousel__caption-subtitle {
	transition-delay: 0.6s;
}

/* line 1715, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .carousel__caption-location, .is-single--detailsanimation .carousel__caption-price, .is-single--detailsanimation .carousel__caption-text {
	transition-delay: 1s;
}

/* line 1721, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .carousel__caption-button-wrapper {
	opacity: 0;
	transition: opacity 0.5s;
}

/* line 1730, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .is-hidden--slide .carousel__details {
	opacity: 0;
}

/* line 1735, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .is-hidden--slide .carousel__caption-title, .is-single--detailsanimation .is-hidden--slide .carousel__caption-subtitle, .is-single--detailsanimation .is-hidden--slide .carousel__caption-location, .is-single--detailsanimation .is-hidden--slide .carousel__caption-price, .is-single--detailsanimation .is-hidden--slide .carousel__caption-text {
	margin-top: 40px;
	opacity: 0;
}

/* line 1744, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .is-hidden--slide .carousel__caption-title {
	top: 40px;
}

/* line 1748, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .is-hidden--slide .carousel__caption-button-wrapper {
	transition-delay: 0.3s;
}

/* line 1757, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .is-center .carousel__details {
	opacity: 1;
}

/* line 1762, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .is-center .carousel__caption-title, .is-single--detailsanimation .is-center .carousel__caption-subtitle, .is-single--detailsanimation .is-center .carousel__caption-location, .is-single--detailsanimation .is-center .carousel__caption-price, .is-single--detailsanimation .is-center .carousel__caption-text {
	margin-top: 0;
	opacity: 1;
}

/* line 1771, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .is-center .carousel__caption-title {
	top: 0;
}

/* line 1775, scss/70-modules/homepageimagecarousel/_module.scss */
.is-single--detailsanimation .is-center .carousel__caption-button-wrapper {
	opacity: 1;
	transition-delay: 1s;
}

/* line 1785, scss/70-modules/homepageimagecarousel/_module.scss */
.m-carousel--no-transition .carousel__track,
.m-carousel--no-transition .carousel__container,
.m-carousel--no-transition .is-center .carousel__details,
.m-carousel--no-transition .carousel__property-details {
	transition: none;
}

/* line 1793, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata {
	position: absolute;
	top: 120px;
	right: 0;
	left: 0;
	bottom: 85px;
	background-color: rgba(var(--color-global-negative), 0.6);
	z-index: 49;
	display: none;
}

/* line 1805, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__text {
	margin-left: 20px;
	margin-top: 20px;
	color: rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__text {
	margin-left: initial;
	margin-right: 20px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__text {
		margin-left: 0;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 1805, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__text {
		text-align: center;
		margin-bottom: 20px;
	}
}

/* line 1816, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__text--title {
	font-size: 22px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 26px;
}

/* line 1824, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__buttons {
	padding-left: 20px;
	padding-right: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__buttons {
	padding-left: initial;
	padding-right: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__buttons {
	padding-right: initial;
	padding-left: 20px;
}

@media only screen and (min-width: 60em) {
	/* line 1824, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__buttons {
		right: 40px;
		padding: 0 0 0 0;
		position: absolute;
		top: 20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__buttons {
		right: auto;
		left: 40px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__buttons {
		padding: 0 0 0 0;
	}
}

/* line 1836, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__buttons--description {
	text-align: center;
	margin-bottom: 10px;
	color: rgb(var(--color-global-border));
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;
}

/* line 1846, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__pills {
	display: flex;
}

/* line 1849, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__pills .carousel-pill {
	padding: 5px 20px 5px 20px;
	background-color: rgba(var(--color-global-negative), 0.2);
	text-transform: uppercase;
	color: #ddd;
	flex: 1;
	border: 1px solid rgba(var(--color-global-positive), 0.7);
	line-height: 15px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__pills .carousel-pill {
	padding: 5px 20px 5px 20px;
}

/* line 1859, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__pills .carousel-pill.active {
	border: 1px solid rgb(var(--color-global-positive));
	color: rgb(var(--color-global-positive));
}

/* line 1864, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__pills .carousel-pill:hover {
	background-color: rgba(var(--color-global-negative), 0.5);
	cursor: pointer;
	border: 1px solid rgb(var(--color-global-positive));
	color: rgb(var(--color-global-positive));
}

/* line 1871, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__pills .carousel-pill:first-of-type {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

/* line 1876, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__pills .carousel-pill:last-of-type {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

/* line 1883, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__charts {
	overflow: auto;
	max-height: 88%;
	position: absolute;
	width: 100%;
	top: 80px;
}

@media only screen and (max-width: 59.999em) {
	/* line 1883, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__charts {
		max-height: 65%;
		top: 135px;
	}
}

/* line 1895, scss/70-modules/homepageimagecarousel/_module.scss */
.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__charts .market-trends__box {
	padding: 0 10px;
	min-height: 300px;
	display: inline-block;
	max-height: 300px;
}

@media only screen and (min-width: 60em) {
	/* line 1903, scss/70-modules/homepageimagecarousel/_module.scss */
	.carousel__marketdata .carousel-marketdata__wrapper .carousel-marketdata__charts .google-visualization-table-table {
		table-layout: fixed;
	}
}

/* line 8, scss/80-themes/hrea/70-modules/homepageimagecarousel/module.scss */
.carousel__details {
	left: 0;
	right: 0;
	font-weight: 600;
}

/* line 13, scss/80-themes/hrea/70-modules/homepageimagecarousel/module.scss */
.carousel__details .carousel__caption-price {
	color: rgb(var(--color-global-border));
	text-shadow: 1px 1px rgba(var(--m-carousel-details-text-color), .2);
}

/* line 9, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .expander__close-button,
.m-carousel .carousel__expander-wrapper .expander__close-button {
	padding: 5px;
	background-color: rgba(0, 0, 0, 0.5);
	right: 10px;
	top: 20px;
}

@media only screen and (max-width: 47.999em) {
	/* line 18, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .expander__close-button,
	.m-carousel .carousel__expander-wrapper .expander__close-button {
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 999;
		color: rgb(var(--color-global-primary));
		font-size: 1em;
		top: 40px;
	}
}

/* line 33, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details:not(.carousel__details--scalable) {
	padding-top: 25px;
	padding-bottom: 25px;
	border-top: 0;
	height: 70px;
	color: rgb(var(--color-global-positive));
	background-color: rgba(var(--color-global-negative), .5);
}

/* line 41, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details:not(.carousel__details--scalable):hover {
	color: rgb(var(--color-global-positive));
}

/* line 45, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details:not(.carousel__details--scalable) .carousel__caption-title + .carousel__caption-price::before {
	content: " | ";
}

/* line 49, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details:not(.carousel__details--scalable) .carousel__caption-title {
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
}

/* line 55, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details:not(.carousel__details--scalable) .carousel__caption-location {
	font-size: 16px;
}

/* line 59, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details:not(.carousel__details--scalable) .carousel__caption-price {
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	color: rgb(var(--color-global-positive));
}

/* line 68, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable {
	border-top: 0;
	padding: 20px;
}

/* line 72, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable:hover {
	color: rgb(var(--color-global-border));
}

/* line 76, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .carousel__expander-caption {
	background: rgba(0, 0, 0, 0.5);
}

/* line 80, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .expander__container-text {
	font-weight: 300;
}

/* line 84, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-price {
	text-shadow: none;
}

/* line 88, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-text-container a {
	color: rgb(var(--color-global-border));
}

/* line 92, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-icons-container {
	display: flex;
	justify-content: space-between;
}

/* line 97, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-icon {
	padding-right: 20px;
	color: rgb(var(--color-global-border));
	font-size: 1rem;
	font-weight: normal;
	text-align: center;
	line-height: 1;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-carousel .carousel__details--scalable .carousel__caption-icon {
	padding-right: initial;
	padding-left: 20px;
}

/* line 105, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-icon .icon {
	font-size: 2rem;
	display: block;
	color: rgb(var(--color-global-border));
	margin-bottom: 5px;
}

/* line 112, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-icon--anchor {
	font-size: 1rem;
}

/* line 116, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-icon:hover {
	color: rgb(var(--color-global-positive));
}

/* line 119, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-icon:hover .icon {
	color: rgb(var(--color-global-positive));
}

/* line 125, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__details--scalable .carousel__caption-title {
	font-size: 1.35rem;
}

@media only screen and (max-width: 47.999em) {
	/* line 68, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__details--scalable {
		padding: 20px 10px 20px 10px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-carousel .carousel__details--scalable {
		padding: 20px 10px 20px 10px;
	}
	/* line 133, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__details--scalable .expander__container-title,
	.m-carousel .carousel__details--scalable .expander__container-text {
		padding-left: 10px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-carousel .carousel__details--scalable .expander__container-title, .is-rtl
	.m-carousel .carousel__details--scalable .expander__container-text {
		padding-left: initial;
		padding-right: 10px;
	}
	/* line 138, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__details--scalable .carousel__details-container {
		flex-direction: column;
	}
	/* line 142, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__details--scalable .carousel__caption-icons-container {
		margin-top: 20px;
		width: 100%;
	}
	/* line 147, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__details--scalable .carousel__caption-icon {
		padding: 0;
		white-space: nowrap;
	}
	/* line 151, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__details--scalable .carousel__caption-icon--combo {
		display: none;
	}
	/* line 157, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__details--scalable .expander__container--description {
		padding-left: 30px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-carousel .carousel__details--scalable .expander__container--description {
		padding-left: initial;
		padding-right: 30px;
	}
	/* line 161, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__details--scalable .expander__container:not(.expander__container--description) {
		padding: 0;
	}
	/* line 166, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__details--scalable .expander__container--description {
		padding-left: 10px;
		display: block;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-carousel .carousel__details--scalable .expander__container--description {
		padding-left: initial;
		padding-right: 10px;
	}
}

@media only screen and (min-width: 48em) {
	/* line 173, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__details--scalable .carousel__caption-icon--single {
		display: none;
	}
}

/* line 182, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__expander-neighborhoods .carousel__location--has-child .carousel__location-link {
	display: block;
}

/* line 189, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__wrapper .carousel__marketdata .carousel-marketdata__wrapper,
.m-carousel .carousel__wrapper .carousel-marketdata__charts .market-trends__box {
	min-height: 330px;
	max-height: 330px;
}

/* line 197, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__neighbourhood-level.level--half-right, .m-carousel .carousel__neighbourhood-level.level--half-left {
	width: 50%;
}

@media only screen and (max-width: 47.999em) {
	/* line 204, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__neighbourhood-level.level--half-right, .m-carousel .carousel__neighbourhood-level.level--half-left {
		width: 100%;
	}
	/* line 211, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__expander-neighborhoods .carousel__neighbourhood-level:not([data-level="0"]) {
		padding-bottom: 0;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 218, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel-marketdata__wrapper .carousel-marketdata__charts {
		max-height: 70%;
	}
	/* line 222, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel-marketdata__wrapper .carousel-pill:first-of-type {
		margin: 0 10px 0 -10px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-carousel .carousel-marketdata__wrapper .carousel-pill:first-of-type {
		margin: 0 -10px 0 10px;
	}
}

@media only screen and (max-width: 29.999em) {
	/* line 228, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.m-carousel .carousel__container {
		height: 85%;
	}
}

/* line 237, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__video-controls--center .carousel__video-controls__control__play:not(.o-loader) {
	box-sizing: border-box;
	padding: 12px 10px 12px 14px;
	width: 40px;
	height: 40px;
}

/* line 243, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.m-carousel .carousel__video-controls--center .carousel__video-controls__control__play:not(.o-loader).icon-pause {
	padding: 12px 10px 12px 15px;
}

/* line 255, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.paw-content .carousel__caption-text-container a {
	cursor: inherit;
}

/* line 259, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.paw-content .carousel__caption-text-container .carousel__caption-status-icon {
	display: none;
}

/* line 264, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.paw-content .m-carousel .carousel__details--scalable {
	padding: 10px 10px 10px 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .paw-content .m-carousel .carousel__details--scalable {
	padding: 10px 10px 10px 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 269, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
	.paw-content .carousel__caption-icons-container {
		display: none;
	}
}

/* line 275, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.carousel__video-player .video-js {
	opacity: 0;
	transition: opacity .75s;
}

/* line 279, scss/80-themes/AffinityDefault/70-modules/homepageimagecarousel/module.scss */
.carousel__video-player .video-js.vjs-has-started, .carousel__video-player .video-js.vjs-playing {
	opacity: 1;
}

/*# sourceMappingURL=../../../../../true */